import Flex from 'components/Flex/Flex';
import Icon from 'components/Icon/Icon';
import Link from 'components/Link/Link';
import Typography from 'components/Typography/Typography';
import { ICON_NAMES } from 'constants/icon';
import { useNavigation } from 'features/navigation/queries';
import { pathnames } from 'i18n/pathnames';
import { Fragment, useState } from 'react';
import { Category } from 'types/Category';
import {
  StyledCategoryLink,
  StyledCategoryLinkDiv,
  StyledCategoryLinkLocalizedLink,
  StyledCategoryWrapper,
  StyledMobileMenuListWrapper,
  StyledMobileReturnButton,
  StyledTitle,
} from './MobileMenuCategoryNavigation.styled';

interface MobileNavigationAccountProps {
  scrollToTop: () => void;
  toggleMobileMenu: (show: boolean) => void;
}

const MobileMenuCategoryNavigation = ({ scrollToTop, toggleMobileMenu }: MobileNavigationAccountProps) => {
  const { data: navigation } = useNavigation();

  const [selectedL1Category, setSelectedL1Category] = useState<Category>();
  const [selectedL2Category, setSelectedL2Category] = useState<Category>();

  const setActiveL1Category = (l1Category: Category) => {
    setSelectedL1Category(l1Category);
    scrollToTop();
  };

  const setActiveL2Category = (l2Category: Category) => {
    setSelectedL2Category(l2Category);
    scrollToTop();
  };

  const resetNavigation = () => {
    setSelectedL1Category(undefined);
    setSelectedL2Category(undefined);
  };

  const resetL2Navigation = () => {
    setSelectedL2Category(undefined);
  };

  const getCategoryLink = (category: Category) => {
    if (category?.qualifier && !category?.clickable) {
      return category.name;
    }

    if (!selectedL2Category) {
      if (category?.qualifier && !category?.subcategoryList?.length) {
        return (
          <StyledCategoryLinkLocalizedLink name={pathnames.CMSPAGE} options={{ query: { slug: category.qualifier } }}>
            {category.name}
          </StyledCategoryLinkLocalizedLink>
        );
      } else {
        return (
          <StyledCategoryLinkDiv onClick={() => setActiveL2Category(category)} role="button" tabIndex={0}>
            {category.name}
            <Icon type="fa" name={ICON_NAMES.ANGLE_RIGHT} size={100} />
          </StyledCategoryLinkDiv>
        );
      }
    } else {
      return (
        <StyledCategoryLink onClick={() => toggleMobileMenu(false)} url={category?.qualifier || category?.url}>
          <Flex alignItems="center" fullWidth justifyContent="space-between">
            {category.name}
            <Icon type="fa" name={ICON_NAMES.ANGLE_RIGHT} size={100} />
          </Flex>
        </StyledCategoryLink>
      );
    }
  };

  return (
    <StyledMobileMenuListWrapper>
      <StyledCategoryWrapper>
        {navigation?.categories?.map((l1Category) => {
          if (!selectedL1Category && !selectedL2Category) {
            return (
              <>
                <StyledCategoryLinkDiv
                  key={l1Category?.code}
                  onClick={() => setActiveL1Category(l1Category)}
                  role="button"
                  tabIndex={0}
                >
                  {l1Category.name}
                  <Icon type="fa" name={ICON_NAMES.ANGLE_RIGHT} size={100} />
                </StyledCategoryLinkDiv>
              </>
            );
          }

          if (selectedL1Category) {
            if (l1Category.code !== selectedL1Category?.code) {
              return null;
            }

            return (
              <Fragment key={l1Category.code}>
                <StyledMobileReturnButton
                  onClick={resetNavigation}
                  marginBottom={!selectedL2Category ? 150 : undefined}
                />
                {!selectedL2Category && (
                  <StyledTitle>
                    {l1Category?.clickable && l1Category?.url ? (
                      <Link url={l1Category?.url} onClick={() => toggleMobileMenu(false)}>
                        {l1Category?.name}
                      </Link>
                    ) : (
                      l1Category?.name
                    )}
                  </StyledTitle>
                )}

                {l1Category.subcategoryList?.map((l2Category) => {
                  if (!selectedL2Category) {
                    return getCategoryLink(l2Category);
                  } else if (selectedL2Category?.code === l2Category?.code) {
                    return (
                      <>
                        <StyledMobileReturnButton
                          onClick={resetL2Navigation}
                          label={l1Category?.name}
                          marginBottom={150}
                        />

                        <StyledCategoryLink
                          onClick={() => toggleMobileMenu(false)}
                          url={l2Category?.qualifier || l2Category?.url}
                        >
                          <Flex alignItems="center" fullWidth justifyContent="space-between">
                            <Typography fontWeight="bold">{l2Category.name}</Typography>
                            <Icon type="fa" iconStyling="fas" name={ICON_NAMES.ANGLE_RIGHT} size={100} />
                          </Flex>
                        </StyledCategoryLink>

                        {l2Category?.subcategoryList?.map((l3Category) => getCategoryLink(l3Category))}
                      </>
                    );
                  }

                  return null;
                })}
              </Fragment>
            );
          }
        })}
      </StyledCategoryWrapper>
    </StyledMobileMenuListWrapper>
  );
};

export default MobileMenuCategoryNavigation;
