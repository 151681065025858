import styled from 'styled-components';
import { specific, variable, color, spacer, fontSize } from 'theme';

const FLY_OVER_HEADER_HEIGHT = '55px';

export const StyledWrapper = styled.div`
  display: flex;
  align-items: 'center';
  width: 100%;
  justify-content: space-between;
  position: relative;
  color: ${color('white')};
  height: ${FLY_OVER_HEADER_HEIGHT};
  background: ${specific('header', 'mobile-menu-header-background-color')};
  ${variable('border-bottom')};
  padding: ${spacer(25)};
`;

export const StyledMobileMenuHeadTitleLogoWrap = styled.div`
  width: 150px;
  height: 100%;
  position: relative;
`;

export const StyledMobileMenuCloseIconWrapper = styled.div`
  margin: auto 0;
  fill: ${specific('header', 'mobile-menu-close-icon-color')};
  font-size: ${fontSize(150)};
  padding-right: ${spacer(100)};
`;
