import CMSLinks from 'components/CMS/CMSLinks/CMSLinks';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { breakpointUp, color, fontSize, spacer, specific, variable, zIndex } from 'theme';
import { BREAKPOINTS } from '../../../constants/breakpoints';
import Backdrop from '../../Backdrop/Backdrop';
import MobileAccountNavigation from './MobileAccountNavigation/MobileAccountNavigation';

const FLY_OVER_HEADER_HEIGHT = '55px';

export const StyledBackdrop = styled(Backdrop)`
  height: 100%;
`;

interface StyledWrapperProps {
  visible?: boolean;
}

export const StyledWrapper = styled.div<StyledWrapperProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex('modal')};
  width: 90vw;
  height: 100%;
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};

  ${breakpointUp(
    BREAKPOINTS.LG,
    css`
      width: 50vw;
    `,
  )}

  ${breakpointUp(
    BREAKPOINTS.XL,
    css`
      width: 30vw;
    `,
  )}
`;

export const StyledMobileMenuOverlay = styled(motion.div)`
  width: 100%;
  height: 100%;
  z-index: ${zIndex('modal')};
  background-color: ${color('white')};
`;

export const StyledMobileMenuHead = styled.div`
  display: flex;
  align-items: 'center';
  width: 100%;
  justify-content: space-between;
  position: relative;
  color: ${color('white')};
  height: ${FLY_OVER_HEADER_HEIGHT};
  background: ${specific('header', 'mobile-menu-header-background-color')};
  ${variable('border-bottom')};
  padding: ${spacer(25)};
`;

export const StyledMobileMenuHeadTitleLogoWrap = styled.div`
  width: 150px;
  height: 100%;
  position: relative;
`;

export const StyledMobileMenuCloseIconWrapper = styled.div`
  margin: auto 0px;
  fill: ${specific('header', 'mobile-menu-close-icon-color')};
  font-size: ${fontSize(150)};
  padding-right: ${spacer(100)};
`;

export const StyledMobileMenuContent = styled.div`
  height: calc(100vh - ${FLY_OVER_HEADER_HEIGHT});
  padding-bottom: ${spacer(400)};
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow-x: hidden;
`;

export const StyledMobileAccountNavigation = styled(MobileAccountNavigation)``;

export const StyledCMSLinks = styled(CMSLinks)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacer(75)};
  ${variable('border-bottom')}
  transition: all 50ms ease;
  background-color: ${color('whisper-100')};
  color: ${specific('header', 'mobile-menu-text-color')} !important;

  &:hover {
    background-color: ${color('shadow-100')};
    color: ${color('secondary-300')} !important;
  }
`;
