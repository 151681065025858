import { apiClient } from 'helpers/ApiClient';
import { Navigation } from 'types/Navigation';
import { logErrorResponse } from 'utils/loggerUtil';

const fetchNavigation = async (locale: string) => {
  try {
    const { data } = await apiClient<Navigation>({
      params: {
        fields: 'NAV',
        lang: locale,
      },
      url: '/categories/navigation',
    });
    return data;
  } catch (error) {
    logErrorResponse('Navigation Connector', error, 'Failed fetching navigation');
    throw error;
  }
};

export { fetchNavigation };
