import Box from 'components/Box/Box';
import Icon from 'components/Icon/Icon';
import ShopLogo from 'components/Layout/ShopLogo/ShopLogo';
import Link from 'components/Link/Link';
import { ICON_NAMES } from 'constants/icon';
import { pathnames } from 'i18n/pathnames';
import { useIntl } from 'react-intl';
import {
  StyledMobileMenuCloseIconWrapper,
  StyledMobileMenuHeadTitleLogoWrap,
  StyledWrapper,
} from './MobileMenuHeader.styled';

interface MobileMenuHeaderProps {
  toggleMobileMenu: (show: boolean) => void;
}

const MobileMenuHeader = ({ toggleMobileMenu }: MobileMenuHeaderProps) => {
  const { formatMessage } = useIntl();

  return (
    <StyledWrapper>
      <Box fullHeight paddingLeft={100}>
        <StyledMobileMenuHeadTitleLogoWrap>
          <Link url={pathnames.INDEX}>
            <ShopLogo />
          </Link>
        </StyledMobileMenuHeadTitleLogoWrap>
      </Box>

      <StyledMobileMenuCloseIconWrapper
        aria-label={formatMessage({
          id: 'mobile_menu_close_button_label',
        })}
        onClick={() => toggleMobileMenu(false)}
        role="button"
        tabIndex={0}
      >
        <Icon type="custom" name={ICON_NAMES.TIMES} size={75} />
      </StyledMobileMenuCloseIconWrapper>
    </StyledWrapper>
  );
};

export default MobileMenuHeader;
