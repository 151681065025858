import { Spacer } from 'constants/styling';
import styled from 'styled-components';
import { color, spacer } from 'theme';

export interface StyledReturnButtonProps {
  marginBottom?: Spacer;
}

export const StyledReturnButton = styled.div<StyledReturnButtonProps>`
  display: flex;
  justify-content: flex-start;
  gap: ${spacer(50)};
  margin-bottom: ${({ marginBottom }) => marginBottom && spacer(marginBottom)};

  &:hover {
    color: ${color('secondary-300')};
  }
`;
