import { keepPreviousData, useQuery } from '@tanstack/react-query';
import useRouter from 'hooks/useRouter';
import { navigationKeys } from '.';
import { fetchNavigation } from './connector';

const useNavigation = () => {
  const { locale } = useRouter();
  return useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => fetchNavigation(locale),
    queryKey: navigationKeys.navigation(locale),
  });
};

export { useNavigation };
