import Link from 'components/Link/Link';
import { LocalizedLink } from 'components/Router';
import Typography from 'components/Typography/Typography';
import styled, { css } from 'styled-components';
import { color, spacer, specific, variable } from 'theme';
import MobileMenuCategoryReturnButton from './MobileMenuCategoryReturnButton/MobileMenuCategoryReturnButton';

export const StyledMobileMenuListWrapper = styled.div`
  padding-bottom: ${spacer(100)};
  width: 100%;
  background-color: ${color('whisper-100')};
`;

export const StyledCategoryWrapper = styled.div`
  > * {
    ${variable('border-bottom')};
  }
`;

export const CategorLinkStyling = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacer(75)};
  text-decoration: none;
  transition: all 50ms ease;
  background-color: ${color('white')};

  && {
    color: ${specific('header', 'mobile-menu-text-color')};
  }

  &:hover {
    background-color: ${color('shadow-100')};
  }
`;

export const StyledTitle = styled(Typography)`
  display: block;
  padding: ${spacer(75)};
  font-weight: bold;
  background-color: ${color('white')};
`;

export const StyledCategoryLinkDiv = styled.div`
  ${CategorLinkStyling}
`;

export const StyledCategoryLinkLocalizedLink = styled(LocalizedLink)`
  ${CategorLinkStyling}
`;

export const StyledCategoryLink = styled(Link)`
  ${CategorLinkStyling}
`;

export const StyledMobileReturnButton = styled(MobileMenuCategoryReturnButton)`
  & {
    ${CategorLinkStyling}
    justify-content: flex-start;
  }
`;
