import styled, { css } from 'styled-components';
import { variable, color, spacer } from 'theme';
import LanguageSwitcher from '../../../Language/LanguageSwitcher/LanguageSwitcher';
import Link from '../../../Link/Link';

const ITEM_STYLING = css`
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: ${spacer(50)};

  &:not(:last-child) {
    ${variable('border-right')};
  }
`;

export const StyledLanguageSwitcher = styled(LanguageSwitcher)`
  display: flex;
  gap: ${spacer(100)};
  font-weight: normal;
  color: ${color('whisper-400')} !important;

  &:hover {
    color: ${color('secondary-300')} !important;
  }
`;

export const StyledMobileNavigationAccount = styled.div`
  display: flex;
  padding: ${spacer(150)};
  justify-content: space-around;
  align-items: center;
  ${variable('border-top')};
  ${variable('border-bottom')};
`;

export const StyledLocalesWrapper = styled.div`
  ${ITEM_STYLING}
`;

export const StyledLink = styled(Link)`
  ${ITEM_STYLING}

  && {
    color: ${color('whisper-400')};

    &:hover {
      color: ${color('secondary-300')};
    }
  }
`;

export const StyledMobileMenuListWrapper = styled.div`
  background-color: ${color('whisper-100')};
  padding-bottom: ${spacer(100)};
  width: 100%;

  ul {
    background-color: ${color('white')};
  }
`;
