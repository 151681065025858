import Icon from 'components/Icon/Icon';
import { ICON_NAMES } from 'constants/icon';
import useAuthResolver from 'hooks/useAuthResolver';
import { pathnames } from 'i18n/pathnames';
import { useIntl } from 'react-intl';
import {
  StyledLanguageSwitcher,
  StyledLink,
  StyledLocalesWrapper,
  StyledMobileNavigationAccount,
} from './MobileAccountNavigation.styled';

interface MobileAccountNavigationProps {
  callback: () => void;
  className?: string;
}

const MobileAccountNavigation = ({ callback, className }: MobileAccountNavigationProps) => {
  const { formatMessage } = useIntl();

  const { anonymous } = useAuthResolver();

  return (
    <StyledMobileNavigationAccount className={className}>
      {/* Account */}
      <StyledLink onClick={callback} url={anonymous ? pathnames.LOGIN : pathnames.MY_ACCOUNT}>
        <Icon type="custom" name={ICON_NAMES.USER} />
        {formatMessage({
          id: anonymous ? 'general_mobile_menu_login_btn' : 'header_myaccount_profile',
        })}
      </StyledLink>

      {/* Languages */}
      <StyledLocalesWrapper>
        <Icon type="custom" name={ICON_NAMES.COMMENT_ALT_LINES} />
        <StyledLanguageSwitcher showDivider={false} />
      </StyledLocalesWrapper>

      {/* Wishlist */}
      <StyledLink onClick={callback} url={pathnames.WISHLIST}>
        <Icon type="custom" name={ICON_NAMES.HEART} />
        {formatMessage({
          id: 'general_mobile_menu_wish_list_btn',
        })}
      </StyledLink>
    </StyledMobileNavigationAccount>
  );
};

export default MobileAccountNavigation;
