import Icon from 'components/Icon/Icon';
import { ICON_NAMES } from 'constants/icon';
import { useIntl } from 'react-intl';
import { StyledReturnButton, StyledReturnButtonProps } from './MobileMenuCategoryReturnButton.styled';

interface MobileMenuCategoryReturnButtonProps extends StyledReturnButtonProps {
  className?: string;
  label?: string;
  onClick: () => void;
}
const MobileMenuCategoryReturnButton = ({
  className,
  label = 'general_return_to_categories_button',
  marginBottom,
  onClick,
}: MobileMenuCategoryReturnButtonProps) => {
  const { formatMessage } = useIntl();
  return (
    <StyledReturnButton
      onClick={onClick}
      className={className}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
      marginBottom={marginBottom}
    >
      <Icon type="fa" name={ICON_NAMES.ANGLE_LEFT} size={100} />

      {formatMessage({
        id: label,
      })}
    </StyledReturnButton>
  );
};

export default MobileMenuCategoryReturnButton;
