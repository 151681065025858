import { DEFAULT_TRANSITION_TIMING, SLIDE_IN_LEFT_ANIMATION } from 'constants/animations';
import { useCmsHeaderLinks } from 'features/cms/queries';
import useWindowSize from 'hooks/useWindowSize';
import { useRef } from 'react';
import { Navigation } from 'types/Navigation';
import {
  StyledBackdrop,
  StyledCMSLinks,
  StyledMobileAccountNavigation,
  StyledMobileMenuContent,
  StyledMobileMenuOverlay,
  StyledWrapper,
} from './MobileMenu.styled';
import MobileMenuCategoryNavigation from './MobileMenuCategoryNavigation/MobileMenuCategoryNavigation';
import MobileMenuHeader from './MobileMenuHeader/MobileMenuHeader';

interface MobileMenuProps {
  navigation?: Navigation;
  toggleMobileMenu: (toggle?: boolean) => void;
  visible?: boolean;
}

const MobileMenu = ({ toggleMobileMenu, visible }: MobileMenuProps) => {
  const { data: cmsHeaderLinks } = useCmsHeaderLinks();
  const { isMobile } = useWindowSize();

  const menuContent = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (menuContent.current && !!menuContent.current.scrollTo) {
      menuContent.current.scrollTo(0, 0);
    }
  };

  if (!isMobile) return null;

  return (
    <StyledBackdrop onClick={() => toggleMobileMenu()} show={visible}>
      <StyledWrapper visible={visible}>
        <StyledMobileMenuOverlay
          animate={visible ? 'enter' : 'exit'}
          initial="exit"
          transition={DEFAULT_TRANSITION_TIMING}
          variants={SLIDE_IN_LEFT_ANIMATION}
        >
          <MobileMenuHeader toggleMobileMenu={toggleMobileMenu} />

          <StyledMobileMenuContent ref={menuContent}>
            {/* Category Link */}
            <MobileMenuCategoryNavigation toggleMobileMenu={toggleMobileMenu} scrollToTop={scrollToTop} />

            {/* My Account - Languages - Wishlist */}
            <StyledMobileAccountNavigation callback={() => toggleMobileMenu(false)} />

            {/* Extra Links */}
            <StyledCMSLinks
              callback={() => toggleMobileMenu(false)}
              linkSlots={cmsHeaderLinks?.orderedSlots}
              position="left"
              withIcon
            />

            <StyledCMSLinks
              callback={() => toggleMobileMenu(false)}
              linkSlots={cmsHeaderLinks?.orderedSlots}
              position="right"
              withIcon
            />
          </StyledMobileMenuContent>
        </StyledMobileMenuOverlay>
      </StyledWrapper>
    </StyledBackdrop>
  );
};

export default MobileMenu;
